import React from "react";
import Content from "../components/Content";
import Gallery from "../components/Gallery";

const About = () => {
  return (
    <>
      <Content />
      <Gallery />
    </>
  );
};

export default About;
